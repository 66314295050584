import { useEffect, useState, useCallback, useRef } from 'react';
import { formatTransactionTime } from './timeUtils';
import { generateOHLCVData, mergeOHLCVData, updateCurrentCandle } from './ohlcvUtils';

const WVTRU_LP_ADDRESS = '0x8B3808260a058ECfFA9b1d0eaA988A1b4167DDba'.toLowerCase();
const WVTRU_TOKEN_ADDRESS = '0x3ccc3F22462cAe34766820894D04a40381201ef9'.toLowerCase();
const USDC_POL_ADDRESS = '0xbCfB3FCa16b12C7756CD6C24f1cC0AC0E38569CF'.toLowerCase();

const processTransaction = (tx) => {
    if (!tx.tokenTransfers || !tx.tokenTransfers.some(transfer =>
        [WVTRU_LP_ADDRESS, WVTRU_TOKEN_ADDRESS, USDC_POL_ADDRESS].includes(transfer.from.toLowerCase()) ||
        [WVTRU_LP_ADDRESS, WVTRU_TOKEN_ADDRESS, USDC_POL_ADDRESS].includes(transfer.to.toLowerCase()))) {
        console.log('Transaction filtered out', tx.hash);
        return null;
    }

    const lpTokenTransfer = tx.tokenTransfers.find(transfer =>
        (transfer.from.toLowerCase() === WVTRU_LP_ADDRESS || transfer.to.toLowerCase() === WVTRU_LP_ADDRESS) &&
        transfer.token.toLowerCase() === WVTRU_TOKEN_ADDRESS
    );

    const usdcTransfer = tx.tokenTransfers.find(transfer =>
        (transfer.from.toLowerCase() === WVTRU_LP_ADDRESS || transfer.to.toLowerCase() === WVTRU_LP_ADDRESS) &&
        transfer.token.toLowerCase() === USDC_POL_ADDRESS
    );

    if (!lpTokenTransfer || !usdcTransfer) {
        console.log('Missing required token transfers', tx.hash);
        return null;
    }

    const isAddLiquidity = tx.functionName.toLowerCase().includes('addliquidity');
    const isRemoveLiquidity = tx.functionName.toLowerCase().includes('removeliquidity');

    let txType;
    let tokenAmount;
    let usdcAmount;

    if (isAddLiquidity || isRemoveLiquidity) {
        txType = isAddLiquidity ? 'add liquidity' : 'remove liquidity';
        tokenAmount = Math.abs(parseFloat(lpTokenTransfer.amount) / 1e18);
        usdcAmount = Math.abs(parseFloat(usdcTransfer.amount) / 1e6);
    } else {
        txType = lpTokenTransfer.to.toLowerCase() === WVTRU_LP_ADDRESS ? 'sell' : 'buy';
        tokenAmount = parseFloat(lpTokenTransfer.amount) / 1e18;
        usdcAmount = parseFloat(usdcTransfer.amount) / 1e6;
    }

    const price = usdcAmount / tokenAmount;

    return {
        hash: tx.hash,
        time: tx.time,
        type: txType,
        total: usdcAmount,
        amount: tokenAmount,
        price: price,
        maker: tx.from,
        block: tx.blockNumber
    };
};

const useWebSocket = (url) => {
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [volume24h, setVolume24h] = useState(0);
    const [transactions24h, setTransactions24h] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState({});
    const [selectedTimeframe, setSelectedTimeframe] = useState('15m');
    const ws = useRef(null);
    const allTransactionsRef = useRef([]);

    const blockToTimestamp = useCallback((blockNumber) => {
        const timeDifference = (blockNumber - 4551586) * 5;
        return new Date((new Date('2024-07-15T19:05:00Z').getTime() / 1000 + timeDifference) * 1000).toISOString();
    }, []);

    const updateChartData = useCallback((newTransactions) => {
        allTransactionsRef.current = [...allTransactionsRef.current, ...newTransactions];
        const newChartData = generateOHLCVData(allTransactionsRef.current, selectedTimeframe);
        setChartData(prevChartData => {
            return {
                ...prevChartData,
                [selectedTimeframe]: mergeOHLCVData(prevChartData[selectedTimeframe] || [], newChartData)
            };
        });
    }, [selectedTimeframe]);

    const handleHistoricalData = useCallback((data) => {
        let processedTransactions = data
            .map(tx => {
                tx.time = blockToTimestamp(tx.blockNumber);
                return processTransaction(tx);
            })
            .filter(tx => tx !== null)
            .sort((a, b) => new Date(a.time) - new Date(b.time));

        setTransactions(prevTransactions => {
            const newTransactions = [...prevTransactions, ...processedTransactions];
            const uniqueTransactions = Array.from(new Map(newTransactions.map(tx => [tx.hash, tx])).values());
            return uniqueTransactions.sort((a, b) => new Date(b.time) - new Date(a.time));
        });

        updateChartData(processedTransactions);

        const now = new Date();
        const recentTransactions = processedTransactions.filter(tx => (now - new Date(tx.time)) < 86400000);

        setVolume24h(prevVolume => {
            const newVolume = recentTransactions.reduce((sum, tx) => sum + tx.total, 0);
            return prevVolume + newVolume;
        });

        setTransactions24h(prevCount => prevCount + recentTransactions.length);

        setIsLoading(false);
    }, [blockToTimestamp, updateChartData]);

    useEffect(() => {
        ws.current = new WebSocket(url);

        ws.current.onopen = () => {
            console.log('WebSocket connected');
            loadMore();
        };

        ws.current.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'historical') {
                handleHistoricalData(message.data);
            } else if (message.type === 'update') {
                const newTx = {
                    ...message.data,
                    time: blockToTimestamp(message.data.blockNumber),
                };
                const processedTransaction = processTransaction(newTx);
                if (processedTransaction) {
                    setTransactions(prevTransactions => [processedTransaction, ...prevTransactions]);
                    updateChartData([processedTransaction]);
                    
                    const now = new Date();
                    if (now - new Date(processedTransaction.time) < 86400000) {
                        setVolume24h(prevVolume => prevVolume + processedTransaction.total);
                        setTransactions24h(prevCount => prevCount + 1);
                    }
                }
            }
        };

        ws.current.onclose = () => {
            console.log('WebSocket disconnected');
        };

        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    }, [url, blockToTimestamp, handleHistoricalData, updateChartData]);

    const loadMore = useCallback(() => {
        if (!isLoading && hasMore && ws.current && ws.current.readyState === WebSocket.OPEN) {
            setIsLoading(true);
            ws.current.send(JSON.stringify({ type: 'getHistorical', pair: 'WVTRU-USDC.POL', page, limit: 250 }));
            setPage(prevPage => prevPage + 1);
        }
    }, [isLoading, hasMore, page]);

    const handleTimeframeChange = useCallback((newTimeframe) => {
        setSelectedTimeframe(newTimeframe);
        const newChartData = generateOHLCVData(allTransactionsRef.current, newTimeframe);
        setChartData(prevChartData => ({
            ...prevChartData,
            [newTimeframe]: newChartData
        }));
    }, []);

    return { 
        transactions, 
        loadMore, 
        hasMore, 
        volume24h, 
        transactions24h, 
        isLoading,
        chartData: chartData[selectedTimeframe] || [],
        selectedTimeframe,
        handleTimeframeChange
    };
};

export default useWebSocket;
