export const formatTransactionTime = (timestamp) => {
    const now = new Date();
    const txTime = new Date(timestamp);
    const timeDifference = Math.floor((now - txTime) / 1000); // time difference in seconds

    const isToday = now.toDateString() === txTime.toDateString();
    const isYesterday = new Date(now - 86400000).toDateString() === txTime.toDateString();

    const formatTimeString = (date) => {
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    };

    if (timeDifference < 60) {
        return 'Just now';
    } else if (timeDifference < 3600) {
        const minutes = Math.floor(timeDifference / 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (timeDifference < 86400 && isToday) {
        const hours = Math.floor(timeDifference / 3600);
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (isToday) {
        return `Today, ${formatTimeString(txTime)}`;
    } else if (isYesterday) {
        return `Yesterday, ${formatTimeString(txTime)}`;
    } else {
        return txTime.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' }) + ', ' + formatTimeString(txTime);
    }
};