import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { updateCurrentCandle } from './ohlcvUtils';  // Import the function here

const PriceChart = React.forwardRef(({ data, timeframe, onUpdateData, extraSpace = true }, ref) => {
    const chartContainerRef = useRef();
    const chartRef = useRef(null);
    const candleSeriesRef = useRef(null);
    const volumeSeriesRef = useRef(null);
    const resizeObserver = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);

    useEffect(() => {
        const handleResize = (entries) => {
            if (entries[0].contentRect) {
                setContainerWidth(entries[0].contentRect.width);
            }
        };

        resizeObserver.current = new ResizeObserver(handleResize);
        resizeObserver.current.observe(chartContainerRef.current);

        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (!chartContainerRef.current || !data.length) return;

        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current.applyOptions({ width: chartContainerRef.current.clientWidth });
            }
        };

        window.addEventListener('resize', handleResize);

        if (!chartRef.current) {
            chartRef.current = createChart(chartContainerRef.current, {
                width: containerWidth,
                height: 400,
                layout: {
                    background: { type: 'solid', color: '#1e1e1e' },
                    textColor: '#d1d4dc',
                },
                grid: {
                    vertLines: { color: '#2b2b43' },
                    horzLines: { color: '#2b2b43' },
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
                rightPriceScale: {
                    borderColor: '#2b2b43',
                },
                timeScale: {
                    borderColor: '#2b2b43',
                    timeVisible: true,
                    secondsVisible: false,
                },
            });

            candleSeriesRef.current = chartRef.current.addCandlestickSeries({
                upColor: '#26a69a',
                downColor: '#ef5350',
                borderVisible: false,
                wickUpColor: '#26a69a',
                wickDownColor: '#ef5350',
            });

            volumeSeriesRef.current = chartRef.current.addHistogramSeries({
                color: '#26a69a',
                priceFormat: {
                    type: 'volume',
                },
                priceScaleId: 'volume',
                scaleMargins: {
                    top: 0.8,
                    bottom: 0,
                },
            });

            chartRef.current.priceScale('volume').applyOptions({
                scaleMargins: {
                    top: 0.8,
                    bottom: 0,
                },
                visible: false,
            });
        }

        const updateChartData = (newTransactions) => {
            if (!candleSeriesRef.current || data.length === 0) return;
        
            // Store the current visible range before any updates
            const visibleRange = chartRef.current.timeScale().getVisibleRange();
        
            // Update only the current candle with new transactions
            updateCurrentCandle(data, newTransactions, timeframe);
        
            // Find the last updated candle
            const lastCandle = data[data.length - 1];
        
            // Apply the update to the candle and volume series
            candleSeriesRef.current.update(lastCandle);
            volumeSeriesRef.current.update({
                time: lastCandle.time,
                value: lastCandle.volume,
                color: lastCandle.close >= lastCandle.open ? '#26a69a' : '#ef5350',
            });
        
            // Restore the visible range to maintain the current zoom level and position
            if (visibleRange) {
                chartRef.current.timeScale().setVisibleRange(visibleRange);
            }
        };

        // Set initial data
        candleSeriesRef.current.setData(data);
        volumeSeriesRef.current.setData(data.map(item => ({
            time: item.time,
            value: item.volume,
            color: item.close >= item.open ? '#26a69a' : '#ef5350'
        })));

        // Set the initial visible range with extra space and show last 200 candles with right padding of 20 pixels
        const visibleRangeFrom = Math.max(data.length - 200, 0); // Ensure at least 200 candles are visible
        chartRef.current.timeScale().setVisibleRange({
            from: data[visibleRangeFrom].time,
            to: data[data.length - 1].time
        });

        chartRef.current.timeScale().applyOptions({
            rightOffset: 20 // Add 20 pixels of padding to the right
        });

        if (onUpdateData) {
            onUpdateData(updateChartData);
        }

        chartRef.current.subscribeCrosshairMove((param) => {
            const tooltipEl = document.getElementById('chart-tooltip');
            if (!param.time || !param.point || !param.seriesData.get(candleSeriesRef.current)) {
                if (tooltipEl) {
                    tooltipEl.style.display = 'none';
                }
                return;
            }

            const coords = param.point;
            const candleData = param.seriesData.get(candleSeriesRef.current);
            const volumeData = param.seriesData.get(volumeSeriesRef.current);
            
            let tooltipContent = '';
            if (candleData) {
                const dateStr = new Date(candleData.time * 1000).toUTCString();
                tooltipContent = `
                    <div>Time: ${dateStr}</div>
                    <div>Open: ${candleData.open.toFixed(6)}</div>
                    <div>High: ${candleData.high.toFixed(6)}</div>
                    <div>Low: ${candleData.low.toFixed(6)}</div>
                    <div>Close: ${candleData.close.toFixed(6)}</div>
                    <div>Volume: ${volumeData ? volumeData.value.toFixed(2) : 'N/A'}</div>
                `;
            }

            if (tooltipEl) {
                tooltipEl.style.display = candleData ? 'block' : 'none';
                tooltipEl.style.left = `${coords.x}px`;
                tooltipEl.style.top = `${coords.y}px`;
                tooltipEl.innerHTML = tooltipContent;
            }
        });

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [data, timeframe, containerWidth, onUpdateData, extraSpace]);

    // Pass ref to parent component if it's provided
    useEffect(() => {
        if (ref) {
            ref.current = {
                chart: chartRef.current,
            };
        }
    }, [ref]);

    return (
        <div style={{ position: 'relative' }}>
            <div ref={chartContainerRef} style={{ height: '400px' }} />
            <div id="chart-tooltip" style={{
                position: 'absolute',
                display: 'none',
                padding: '8px',
                boxSizing: 'border-box',
                fontSize: '12px',
                color: '#fff',
                background: 'rgba(255, 255, 255, 0.23)',
                textAlign: 'left',
                zIndex: 1000,
                pointerEvents: 'none',
            }} />
        </div>
    );
});

export default PriceChart;
