import React from 'react';
import { formatTransactionTime } from './timeUtils';
import { ExternalLink } from 'lucide-react';

const TransactionsTable = ({ transactions }) => {
    const shortenAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };

    return (
        <div style={{ backgroundColor: '#333', padding: '16px', borderRadius: '4px', position: 'relative' }}>
            <div style={{ overflowX: 'auto', maxHeight: '500px' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: '#333', zIndex: 2 }}>
                        <tr style={{ borderBottom: '1px solid #444' }}>
                            <th style={{ textAlign: 'left', padding: '8px', color: '#bbb', width: '15%' }}>Time</th>
                            <th style={{ textAlign: 'right', padding: '8px', color: '#bbb', width: '10%' }}>Block#</th>
                            <th style={{ textAlign: 'left', padding: '8px', color: '#bbb', width: '10%' }}>Type</th>
                            <th style={{ textAlign: 'right', padding: '8px', color: '#bbb', width: '10%' }}>USD Value</th>
                            <th style={{ textAlign: 'right', padding: '8px', color: '#bbb', width: '10%' }}>WVTRU</th>
                            <th style={{ textAlign: 'right', padding: '8px', color: '#bbb', width: '10%' }}>USDC.POL</th>
                            <th style={{ textAlign: 'right', padding: '8px', color: '#bbb', width: '10%' }}>Price</th>
                            <th style={{ textAlign: 'left', padding: '8px', color: '#bbb', width: '15%' }}>Maker</th>
                            <th style={{ textAlign: 'left', padding: '8px', color: '#bbb', width: '10%' }}>Txn</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((tx) => (
                            <tr key={tx.hash} style={{ borderBottom: '1px solid #444' }}>
                                <td style={{ textAlign: 'left', padding: '8px', width: '15%', color: '#eee' }}>{formatTransactionTime(tx.time)}</td>
                                <td style={{ textAlign: 'right', padding: '8px', width: '10%', color: '#eee' }}>{tx.block}</td>
                                <td style={{ textAlign: 'left', padding: '8px', width: '10%', color: tx.type === 'buy' ? '#4CAF50' : tx.type === 'sell' ? '#F44336' : '#eee' }}>
                                    {tx.type}
                                </td>
                                <td style={{ textAlign: 'right', padding: '8px', width: '10%', color: '#eee' }}>${tx.total.toFixed(4)}</td>
                                <td style={{ textAlign: 'right', padding: '8px', width: '10%', color: '#eee' }}>{tx.amount.toFixed(4)}</td>
                                <td style={{ textAlign: 'right', padding: '8px', width: '10%', color: '#eee' }}>{tx.total.toFixed(4)}</td>
                                <td style={{ textAlign: 'right', padding: '8px', width: '10%', color: '#eee' }}>${tx.price.toFixed(6)}</td>
                                <td style={{ textAlign: 'left', padding: '8px', width: '15%', color: '#eee' }}>{shortenAddress(tx.maker)}</td>
                                <td style={{ textAlign: 'left', padding: '8px', width: '10%', color: '#eee' }}>
                                    <span style={{ marginRight: '5px' }}>{shortenAddress(tx.hash)}</span>
                                    <a href={`https://explorer.vitruveo.xyz/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer" style={{ color: '#4A90E2', textDecoration: 'none', display: 'inline-block', verticalAlign: 'middle' }}>
                                        <ExternalLink style={{ width: '16px', height: '16px' }} />
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TransactionsTable;
