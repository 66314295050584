import moment from 'moment';

const timeframes = {
  '5m': 5 * 60 * 1000,
  '15m': 15 * 60 * 1000,
  '1h': 60 * 60 * 1000,
  '4h': 4 * 60 * 60 * 1000,
  '1d': 24 * 60 * 60 * 1000
};

export const generateOHLCVData = (transactions, timeframe) => {
  const interval = timeframes[timeframe];
  const ohlcvMap = new Map();
  const sortedTransactions = transactions.sort((a, b) => moment(a.time).valueOf() - moment(b.time).valueOf());

  let prevClose = null;
  let firstCandleTime = null;
  let lastCandleTime = null;

  sortedTransactions.forEach((tx, index) => {
    const txTime = moment(tx.time);
    const candleTime = Math.floor(txTime.valueOf() / interval) * interval / 1000;
    const txPrice = parseFloat(tx.price);

    if (index === 0) {
      firstCandleTime = candleTime;
    }
    lastCandleTime = candleTime;

    if (!ohlcvMap.has(candleTime)) {
      ohlcvMap.set(candleTime, {
        time: candleTime,
        open: prevClose !== null ? prevClose : txPrice,
        high: txPrice,
        low: txPrice,
        close: txPrice,
        volume: parseFloat(tx.amount)
      });
    } else {
      const candle = ohlcvMap.get(candleTime);
      candle.high = Math.max(candle.high, txPrice);
      candle.low = Math.min(candle.low, txPrice);
      candle.close = txPrice;
      candle.volume += parseFloat(tx.amount);
    }

    prevClose = txPrice;
  });

  // Fill in empty candles and ensure continuity
  if (firstCandleTime !== null && lastCandleTime !== null) {
    for (let time = firstCandleTime; time <= lastCandleTime; time += interval / 1000) {
      if (!ohlcvMap.has(time)) {
        const prevCandle = ohlcvMap.get(time - interval / 1000);
        const prevClose = prevCandle ? prevCandle.close : (ohlcvMap.get(firstCandleTime).open);
        ohlcvMap.set(time, {
          time: time,
          open: prevClose,
          high: prevClose,
          low: prevClose,
          close: prevClose,
          volume: 0
        });
      } else {
        const currentCandle = ohlcvMap.get(time);
        const prevCandle = ohlcvMap.get(time - interval / 1000);
        if (prevCandle) {
          currentCandle.open = prevCandle.close;
        }
      }
    }
  }

  return Array.from(ohlcvMap.values()).sort((a, b) => a.time - b.time);
};

export const mergeOHLCVData = (existingData, newData) => {
  const mergedMap = new Map(existingData.map(candle => [candle.time, candle]));
  
  newData.forEach(newCandle => {
    if (mergedMap.has(newCandle.time)) {
      const existingCandle = mergedMap.get(newCandle.time);
      mergedMap.set(newCandle.time, {
        time: newCandle.time,
        open: existingCandle.open,
        high: Math.max(existingCandle.high, newCandle.high),
        low: Math.min(existingCandle.low, newCandle.low),
        close: newCandle.close,
        volume: existingCandle.volume + newCandle.volume
      });
    } else {
      mergedMap.set(newCandle.time, newCandle);
    }
  });

  return Array.from(mergedMap.values()).sort((a, b) => a.time - b.time);
};

export const updateCurrentCandle = (chartData, newTransaction, timeframe) => {
  const interval = timeframes[timeframe];
  const txTime = moment(newTransaction.time);
  const candleTime = Math.floor(txTime.valueOf() / interval) * interval / 1000;
  const txPrice = parseFloat(newTransaction.price);
  const txVolume = parseFloat(newTransaction.amount);

  let updatedChartData = [...chartData];
  const lastCandle = updatedChartData[updatedChartData.length - 1];

  if (lastCandle && lastCandle.time === candleTime) {
    // Update the current candle
    lastCandle.high = Math.max(lastCandle.high, txPrice);
    lastCandle.low = Math.min(lastCandle.low, txPrice);
    lastCandle.close = txPrice;
    lastCandle.volume += txVolume;
  } else {
    // Create a new candle
    const newCandle = {
      time: candleTime,
      open: lastCandle ? lastCandle.close : txPrice,
      high: txPrice,
      low: txPrice,
      close: txPrice,
      volume: txVolume
    };

    // Fill in any missing candles
    if (lastCandle) {
      for (let time = lastCandle.time + interval / 1000; time < candleTime; time += interval / 1000) {
        updatedChartData.push({
          time: time,
          open: lastCandle.close,
          high: lastCandle.close,
          low: lastCandle.close,
          close: lastCandle.close,
          volume: 0
        });
      }
    }

    updatedChartData.push(newCandle);
  }

  return updatedChartData;
};