import React, { useState, useEffect, useRef } from 'react';
import useWebSocket from './useWebSocket';
import TransactionsTable from './TransactionsTable';
import PriceChart from './PriceChart';
import InfiniteScroll from 'react-infinite-scroll-component';

const App = () => {
    const {
        transactions, 
        loadMore, 
        hasMore, 
        isLoading, 
        chartData, 
        selectedTimeframe, 
        handleTimeframeChange,
        volume24h, // Assuming this is provided by useWebSocket
        transactions24h, // Assuming this is provided by useWebSocket
        totalTransactions
    } = useWebSocket('wss://charts.4vitruveo.xyz');

    const chartContainerRef = useRef(null);
    const [showCopiedNotification, setShowCopiedNotification] = useState(false);

    const handleCopyAddress = () => {
        navigator.clipboard.writeText('0xb7A281EF95662124a76AbDe7CbCd907Ae1cAFcB8');
        setShowCopiedNotification(true);
        setTimeout(() => setShowCopiedNotification(false), 2000);
    };

    const scrollChartToStart = () => {
        if (chartContainerRef.current && chartContainerRef.current.chart) {
            const chart = chartContainerRef.current.chart;
            const timeScale = chart.timeScale();
            const visibleLogicalRange = timeScale.getVisibleLogicalRange();
            const barsRange = visibleLogicalRange.to - visibleLogicalRange.from;

            timeScale.setVisibleLogicalRange({
                from: 0,
                to: barsRange
            });
        }
    };

    const scrollChartToEnd = () => {
        if (chartContainerRef.current && chartContainerRef.current.chart) {
            const chart = chartContainerRef.current.chart;
            const timeScale = chart.timeScale();
            const visibleLogicalRange = timeScale.getVisibleLogicalRange();
            const barsRange = visibleLogicalRange.to - visibleLogicalRange.from;

            const lastIndex = chartData.length - 1;
            timeScale.setVisibleLogicalRange({
                from: lastIndex - barsRange,
                to: lastIndex
            });
        }
    };

    const handleLoadMoreClick = () => {
        loadMore();
    };

    return (
        <div style={{
            backgroundColor: '#000000',
            color: 'white',
            minHeight: '100vh',
            padding: '20px',
            position: 'relative'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
            }}>
                <h1 style={{ fontSize: '24px', fontWeight: 'bold' }}>WVTRU/USDC.POL</h1>
                <div style={{ textAlign: 'center' }}>
                    <p>Like my work? Let me know (Tazmaan in discord)</p>
                    <p>Buy me a beer (VIBE, VTRU, WVTRU, VTRO, USDC accepted)</p>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ marginRight: '5px' }}>0xb7A281EF95662124a76AbDe7CbCd907Ae1cAFcB8</span>
                        <button
                            onClick={handleCopyAddress}
                            style={{
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                padding: 0
                            }}
                        >
                            {showCopiedNotification ? '✅' : '📋'}
                        </button>
                    </div>
                </div>
                <div style={{
                    fontSize: '24px',
                    fontWeight: 'bold'
                }}>
                    ${transactions[0]?.price.toFixed(6) || 'Loading...'}
                </div>
            </div>

            <div style={{ marginBottom: '20px', position: 'relative', minHeight: '400px' }}>
                <PriceChart 
                    ref={chartContainerRef}
                    data={chartData} 
                    timeframe={selectedTimeframe}
                />
                <button onClick={scrollChartToStart} style={{ position: 'absolute', left: '10px', top: '50%' }}>
                    {'<<'}
                </button>
                <button onClick={scrollChartToEnd} style={{ position: 'absolute', right: '10px', top: '50%' }}>
                    {'>>'}
                </button>
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', alignItems: 'center', marginBottom: '20px' }}>
                <div style={{ textAlign: 'center' }}>
                    {['5m', '15m', '1h', '4h', '1d'].map(timeframe => (
                        <button
                            key={timeframe}
                            onClick={() => handleTimeframeChange(timeframe)}
                            style={{
                                marginRight: '8px',
                                backgroundColor: selectedTimeframe === timeframe ? '#4A90E2' : '#333',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer'
                            }}
                        >
                            {timeframe}
                        </button>
                    ))}
                </div>
                <div style={{ textAlign: 'center' }}>
                    <p>Volume (24hr)</p>
                    <p>{volume24h ? `$${volume24h.toFixed(2)}` : 'Loading...'}</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <p>Transactions (24hr)</p>
                    <p>{transactions24h || 'Loading...'}</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <button onClick={handleLoadMoreClick} style={{ backgroundColor: '#4A90E2', color: 'white', padding: '10px', border: 'none', cursor: 'pointer' }}>
                        Load More Data
                    </button>
                </div>
            </div>

            <InfiniteScroll
                dataLength={transactions.length}
                next={loadMore}
                hasMore={hasMore}
                loader={isLoading ? <h4>Loading...</h4> : null}
                style={{ overflow: 'auto' }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                    <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>Recent Transactions</h2>
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        Last {transactions.length} displayed
                    </span>
                </div>
                <TransactionsTable transactions={transactions} />
            </InfiniteScroll>
        </div>
    );
};

export default App;
